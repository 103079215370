<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    @click:outside="goBack"
  >
    <v-card>
      <v-card-title class="pa-0">
        <span class="pt-4 px-4 pb-1">
          {{ title }}
        </span>
        <v-tabs v-model="activeTab">
          <v-tab v-for="(config, index) in selectedProject.configurations" :key="config.id">
            {{ getTabTitle(config, index) }}
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text class="px-0 pt-1">
        <v-tabs-items v-model="activeTab">
          <v-tab-item
            v-for="config in selectedProject.configurations"
            :key="config.id"
            class="project-configuration-tab"
          >
            <v-simple-table dense>
              <tbody>
                <template v-for="(value, key) in config">
                  <tr v-if="!key.includes('id')" :key="key">
                    <td :class="{ 'configuration-key-cell': $vuetify.breakpoint.smAndUp }">
                      <div class="text-no-wrap">
                        {{ $t(`projects.labels.${key}`) }}
                      </div>
                      <div v-if="$vuetify.breakpoint.xsOnly">
                        <div class="configuration-value">
                          <a
                            v-if="key.includes('url') && value"
                            :href="getFullUrl(value)"
                            target="_blank"
                            class="link configuration-value"
                          >
                            {{ value }}
                          </a>
                          <div v-else>
                            {{ getValueLabel(value) }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td v-if="$vuetify.breakpoint.smAndUp">
                      <div class="configuration-value">
                        <a
                          v-if="key.includes('url') && value"
                          :href="getFullUrl(value)"
                          target="_blank"
                          class="link"
                        >
                          {{ value }}
                        </a>
                        <div v-else>
                          {{ getValueLabel(value) }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="goBack">
          {{ $t('general.controls.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';

export default {
  name: 'ProjectConfigurations',

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    ...mapState('projects', ['selectedProject']),

    title() {
      return `${this.selectedProject.title} ${this.$t('general.configurations').toLowerCase()}`;
    },
  },

  beforeRouteEnter(to, from, next) {
    if (
      !store.state.projects.selectedProject.configurations?.length ||
      store.getters['projects/currentUserPermissionInSelectedProject'] === 'client'
    ) {
      next({ name: 'project', params: { projectId: to.params.projectId } });
    } else {
      next();
    }
  },

  methods: {
    getFullUrl(url) {
      return ['mailto:', 'tel:', 'http://', 'https://'].every((prefix) => !url.includes(prefix))
        ? `https://${url}`
        : url;
    },

    getTabTitle(config, index) {
      return config.url_server_production || `${this.$t('general.configuration')} ${index + 1}`;
    },

    getValueLabel(value) {
      if (typeof value === 'boolean') {
        return this.$t(`general.controls.${value ? 'yes' : 'no'}`);
      }
      return value || '-';
    },

    goBack() {
      this.$router.push({ name: 'project' });
    },
  },
};
</script>

<style scoped>
.configuration-value {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.configuration-key-cell {
  width: 285px;
}
</style>
